import React from "react";

const Divider = ({ text, marginTop = "20px", marginBottom = "20px" }) => {
    const styles = {
        container: {
            display: "flex",
            alignItems: "center",
            marginTop,
            marginBottom
        },
        line: {
            flex: 1,
            border: "none",
            borderTop: "2px solid #ccc",
        },
        text: {
            margin: "0 10px",
            fontSize: "14px",
            color: "#666",
        },
    };

    return (
        <div style={styles.container}>
            <hr style={styles.line} />
            {text && <span style={styles.text}>{text}</span>}
            <hr style={styles.line} />
        </div>
    );
};

export default Divider;
