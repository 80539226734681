const INFO = {
	main: {
		title: "Portfolio by Dennis",
		name: "Dennis Huynh",
		email: "dennishuynh0119@gmail.com",
		logo: "../dennis.png",
	},

	socials: {
		// twitter: "https://twitter.com/",
		github: "https://github.com/ddhuynh5",
		linkedin: "https://www.linkedin.com/in/ddhuynh123/",
		// instagram: "https://instagram.com/",
		// stackoverflow: "https://stackoverflow.com/",
		// facebook: "https://facebook.com/",
		school: "https://www.clemson.edu/index.html"
	},

	homepage: {
		title: "Hi, I'm Dennis 👋!",
		description:
			`A software engineer from Greenville, SC with experience in building secure, scalable 
			applications. I enjoy solving complex problems, optimizing backend systems, 
			and creating dynamic user interfaces. I am driven by a passion for learning, high-quality code, and taking on new challenges.`,
	},

	/* about: {
		title: "I’m Dennis H. I live in Greenville, SC, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	}, */

	projects: [
		{
			title: "DenBot",
			description:
				"A Discord bot that fixes Twitter/X embeds, purges messages, cat pics, and more",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "See it in action!",
			link: "../bot_showcase.gif",
			gif: true
		},

		{
			title: "Deep Learning With Husky Unmanned Ground Vehicle",
			description:
				"A convolutional neural network model that enables a rover to autonomously traverse through various terrain",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View on GitHub",
			link: "https://github.com/ddhuynh5/HuskyRobot",
			gif: false
		},

		{
			title: "TruckTruckGo",
			description:
				"A platform designed to incentivize safer driving for truck drivers through a point-based reward system. The application aims to foster a culture of safety and accountability on the road, encouraging drivers to adopt safer driving habits",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://truck-truck-go.vercel.app/",
			gif: false
		},
	],

	works: [
		{
			place: "98 Ventures",
			position: "Software Engineer",
			duration: "April 2024 - Present",
			logo: "../../98.png",
			link: "https://www.98ventures.com/"
		},
		{
			place: "BMW",
			position: "Software Engineering Co-Op",
			duration: "May 2023 - August 2023",
			logo: "../../bmw.png",
			link: "https://www.bmwgroup-werke.com/spartanburg/en.html"
		},
		{
			place: "Clemson University",
			position: "Data Science Intern",
			duration: "January 2023 - August 2023",
			logo: "../../clemson.png",
			link: "https://cufacilities.sites.clemson.edu/"
		},
		{
			place: "BMW",
			position: "Software Engineering Co-Op",
			duration: "August 2022 - December 2022",
			logo: "../../bmw.png",
			link: "https://www.bmwgroup-werke.com/spartanburg/en.html"
		},
		{
			place: "Verint Systems Inc.",
			position: "Data Science Intern",
			duration: "May 2022 - August 2022",
			logo: "../../verint.png",
			link: "https://www.verint.com/"
		},
	],
};

export default INFO;
