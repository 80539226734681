import React, { useEffect, useState } from "react";

const ThemeSwitcher = () => {
    const [theme, setTheme] = useState("light");

    useEffect(() => {
        const savedTheme = localStorage.getItem("theme") || "system";
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

        if (savedTheme === "dark" || (savedTheme === "system" && prefersDark)) {
            setTheme("dark");
            document.documentElement.classList.add("dark-theme");
        } else {
            setTheme("light");
            document.documentElement.classList.add("light-theme");
        }
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        document.documentElement.classList.toggle("dark-theme", newTheme === "dark");
        document.documentElement.classList.toggle("light-theme", newTheme === "light");
        localStorage.setItem("theme", newTheme);
    };

    return (
        <button onClick={toggleTheme} aria-label="Toggle Theme">
            {theme === "light" ? "🌙" : "☀️"}
        </button>
    );
};

export default ThemeSwitcher;
