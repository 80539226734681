import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/project.css";

const Project = (props) => {
	const { logo, title, description, linkText, link, gif } = props;

	return (
		<React.Fragment>
			<div className="project">
				{!gif ?
					<a href={link} target="_blank" rel="noopener noreferrer" title="Click to open in new tab">
						<div className="project-container">
							<div className="project-logo">
								<img src={logo} alt="logo" />
							</div>
							<div className="project-title">{title}</div>
							<div className="project-description">{description}</div>
							<div className="project-link">
								<div className="project-link-icon">
									<FontAwesomeIcon icon={faLink} />
								</div>
								<div className="project-link-text">{linkText}</div>
							</div>
						</div>
					</a>
					:
					<a href={link} target="_blank" rel="noopener noreferrer" title="Click to open in new tab">
						<div className="project-container">
							<div className="project-logo">
								<img src={logo} alt="logo" />
							</div>
							<div className="project-title">{title}</div>
							<div className="project-description">{description}</div>
							<div className="project-link">
								<div className="project-link-icon">
									<FontAwesomeIcon icon={faLink} />
								</div>
								<div className="project-link-text">{linkText}</div>
							</div>
						</div>
					</a>
				}
			</div>
		</React.Fragment>
	);
};

export default Project;
