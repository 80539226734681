import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import ThemeSwitcher from "./themeswitcher";

import "./styles/navBar.css";

const NavBar = (/* props */) => {
	// const { active } = props;
	const [active, setActive] = useState("#home");

	const scrollToSection = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		const ids = ["home", "projects", "work"];
		const elements = ids.map(id => document.getElementById(id));

		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.95
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setActive(entry.target.getAttribute("id"));
				}
			});
		}, options);

		elements.forEach(element => {
			if (element) {
				observer.observe(element);
			}
		});

		return () => {
			elements.forEach(element => {
				if (element) {
					observer.unobserve(element);
				}
			});
		};
	}, []);

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<ul className="nav-list">
							<li
								className={
									active === "home"
										? "nav-item active"
										: "nav-item"
								}
							>
								<a href="#home" onClick={(e) => { e.preventDefault(); scrollToSection("home"); }}>Home</a>
							</li>
							{/* <li
								className={
									active === "about"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/">About</Link>
							</li> */}
							<li
								className={
									active === "projects"
										? "nav-item active"
										: "nav-item"
								}
							>
								<a href="#projects" onClick={(e) => { e.preventDefault(); scrollToSection("projects"); }}>Projects</a>
							</li>
							{/* <li
								className={
									active === "articles"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/articles">Articles</Link>
							</li>
							<li
								className={
									active === "contact"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/contact">Contact</Link>
							</li> */}
							<li
								className={
									active === "work"
										? "nav-item active"
										: "nav-item"
								}
							>
								<a href="#work" onClick={(e) => { e.preventDefault(); scrollToSection("work"); }}>Work</a>
							</li>
							<ThemeSwitcher />
						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
