import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import Card from "../common/card";
import INFO from "../../data/user";

import "./styles/works.css";

const Works = () => {
	return (
		<Card
			icon={faBriefcase}
			title="Work"
			body={INFO.works.map((work, index) => (
				<div className="works-body cursor-pointer" key={(index + 1).toString()}>
					<a href={work.link} target="_blank" rel="noreferrer">
						<div className="work">
							<div className="work-header">
								<img
									src={work.logo}
									alt={work.place}
									className="work-image"
								/>
								<div className="work-title">{work.place}</div>
							</div>
							<div className="work-details">
								<div className="work-subtitle">{work.position}</div>
								<div className="work-duration">{work.duration}</div>
							</div>
						</div>
					</a>
				</div>
			))}
		/>
	);
};

export default Works;
